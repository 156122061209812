import { Box, Text } from "theme-ui"
import React from "react"
import { Roll } from "react-awesome-reveal"

interface SolutionTextBoxProps {
  children: React.ReactNode
}

const SolutionTextBox = (props: SolutionTextBoxProps) => {
  return (
    <Box
      p={4}
      sx={{ fontSize: 2, textAlign: "center" }}
      color="accent2"
      bg="highlight6"
    >
      <Roll triggerOnce={true} duration={500}>
        <Text>{props.children}</Text>
      </Roll>
    </Box>
  )
}

export default SolutionTextBox
