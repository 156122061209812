import Banner from "../../components/Banner"
import SolutionGrid from "../../components/SolutionsGrid"
import { Text, Box } from "theme-ui"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import SEO from "../../components/seo"
import SolutionTextBox from "../../components/SolutionTextBox"

export const query = graphql`
  query {
    mobileBanner: file(
      relativePath: { eq: "solutionsOutdoorBannerMobile.png" }
    ) {
      ...fluidImage
    }
    desktopBanner: file(relativePath: { eq: "solutionsOutdoorBanner.png" }) {
      ...fluidImage
    }
    imageOne: file(relativePath: { eq: "solutionsOutdoor1.png" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "solutionsOutdoor2.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "solutionsOutdoor3.png" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "solutionsOutdoor4.png" }) {
      ...fluidImage
    }
    imageFive: file(relativePath: { eq: "solutionsOutdoor5.png" }) {
      ...fluidImage
    }
    imageSix: file(relativePath: { eq: "solutionsOutdoor6.png" }) {
      ...fluidImage
    }
    imageSeven: file(relativePath: { eq: "solutionsOutdoor7.png" }) {
      ...fluidImage
    }
    imageEight: file(relativePath: { eq: "solutionsOutdoor8.png" }) {
      ...fluidImage
    }
  }
`

export default function Outdoors({ data }) {
  const images = [
    data.imageOne.childImageSharp.fluid,
    data.imageTwo.childImageSharp.fluid,
    data.imageThree.childImageSharp.fluid,
    data.imageFour.childImageSharp.fluid,
    data.imageFive.childImageSharp.fluid,
    data.imageSix.childImageSharp.fluid,
    data.imageSeven.childImageSharp.fluid,
    data.imageEight.childImageSharp.fluid,
  ]

  return (
    <Layout>
      <SEO title="Outdoors Solutions" />
      <Banner
        desktopImage={data.desktopBanner.childImageSharp.fluid}
        mobileImage={data.mobileBanner.childImageSharp.fluid}
        heading="Find a solution that suits your OUTDOORS!"
        body={
          <Text variant="default" color="accent">
            From terraces to gardens, from public spaces to highways, and from
            avenues to medians we provide customised solutions.
          </Text>
        }
        alt="Outdoors Solutions"
      />
      <SolutionGrid>
        {images.map((image, index) => {
          return <Img key={index} fluid={image} alt="outdoor solution" />
        })}
        <Box
          sx={{
            gridArea: [
              "1 / 1 / 2 / -1",
              null,
              null,
              null,
              "1 / 3 / span 4 / 4",
            ],
          }}
        >
          <SolutionTextBox>
            For the green movement to take root, we have to take into
            consideration not just our homes and offices but also our
            surroundings. We work with large corporates as well as individuals
            to plant dedicated green spaces outside their buildings and homes,
            to bring in elements of nature, essential for the well-being of the
            residents as well as the environment. Our solution ranges from
            custom made green barriers, large swathes of landscaping, planting
            of native plants and trees to attract local birds, well designed
            gardens and public spaces, and also water bodies that help in
            maintaining the soil quality and water table.
          </SolutionTextBox>
        </Box>
      </SolutionGrid>
    </Layout>
  )
}
