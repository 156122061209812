import React from "react"
import { Box, Grid } from "theme-ui"

interface SolutionsGridProps {
  children: React.ReactNode
}

const SolutionsGrid = (props: SolutionsGridProps): JSX.Element => {
  return (
    <Box m={[3, 5]}>
      <Grid gap={5} m={1} columns={[1, 2, 3]}>
        {props.children}
      </Grid>
    </Box>
  )
}

export default SolutionsGrid
